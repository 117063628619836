@import '@styles/common/variables';

.partner-list-container {
    .list-header {
        @include max-size(577px) {
            flex-direction: column;
            gap: 8px;
            align-items: normal;
        }
        display: flex;
        justify-content: space-between;
        padding: 1rem 0rem;
        align-items: center;

        .btn-add-new {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid transparent;
            padding: 0.625rem 1.25rem;
            border-radius: 0.375rem;
        }
    }

    .size-price {
        padding-top: 0px;
    }

    .item-name {
        color: #4e5a65;
        font-weight: 500;
        @include ellipsis(1);
    }

    .pd-0 {
        padding: 0;
    }

    .ant-avatar-icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .pagination-container {
        border: 1px solid #eaecf0;
        border-top: none;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}

.contact-dropdown-container {
    .ant-dropdown-menu-item {
        height: 44px;

        .ant-btn {
            padding: 0;
        }

        .anticon svg {
            font-size: 18px;
        }

        .ant-dropdown-menu-title-content {
            font-size: 16px;
        }

        .ant-btn {
            border: 0;
            background: transparent;
        }
    }
    .ant-tooltip-arrow {
        display: none;
    }
}
