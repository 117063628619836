@import '@styles/common/variables';

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .previous-btn {
        position: absolute;
        left: 0px;
    }

    .previous-btn,
    .next-btn {
        .ant-btn:disabled {
            color: rgba(0, 0, 0, 0.25);
            border-color: #d9d9d9;
            background: #f5f5f5;
            text-shadow: none;
            box-shadow: none;

            .icon-action {
                opacity: 0.2;
            }
        }
    }

    .next-btn {
        position: absolute;
        right: 0px;
    }

    .ant-pagination-item {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        border: none;

        color: #667085;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        height: 40px;
        width: 40px;
    }

    .ant-pagination-item-active {
        background: #f9fafb;
        border-radius: 8px;
        color: #1d2939;
        font-weight: bold;
    }

    .ant-btn {
        background: #ffffff;
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        font-weight: 500;
        color: #344054;
        font-size: 16px;
        line-height: 24px;
        padding: 8px 14px;
        height: 40px;
    }
}
