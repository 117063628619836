@import '@styles/common/variables';
@import '@styles/common/mixins';

.marketplace-setting {
    min-height: 500px;
    background: #ffffff;
    font-family: $font-inter;
    padding: 1.5rem 2.5rem;

    h3 {
        font-size: 21px;
        font-weight: 700;
    }

    .time {
        height: 40px;
    }

    .text {
        font-size: 0.875rem;
        font-weight: 600;
        margin-bottom: 0px;
        color: #626262;
    }

    .ant-upload.ant-upload-select-picture-card {
        border-radius: 12px;
        &:hover {
            background: #d9d9d9;
        }
    }

    .custom-select {
        .ant-select-selector {
            padding-top: 5px;
            height: 40px;
            .ant-select-selection-search {
                display: flex;
                align-items: center;
            }
        }
    }
    .input {
        border-radius: 6px;
        background: $white;
        height: rem(40);
        @include text-style(14px, 700, 24px, $neutral-100);

        &::placeholder {
            font-weight: 400;
        }
    }
    .add-banner-btn {
        width: rem(150);
    }

    .form-container {
        .banner-config-container {
            .ant-form-item {
                margin-bottom: 16px;
                .ant-row {
                    display: unset;
                    .ant-col {
                        ::after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.banner {
    .ant-upload.ant-upload-select-picture-card,
    .ant-upload.ant-upload-select {
        width: 100% !important;
        height: auto !important;
        background-color: rgba(0, 0, 0, 0.02) !important;
        border: 1px dashed #d9d9d9 !important;
        border-radius: 0.75rem !important;
    }
    .ant-upload {
        img {
            width: 100%;
            margin: unset;
        }
    }

    &.banner-not-value {
        .ant-upload.ant-upload-select-picture-card,
        .ant-upload.ant-upload-select {
            width: 100% !important;
            height: 200px !important;
        }
    }
}
