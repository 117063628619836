@import '@styles/common/variables';
@import '@styles/common/mixins';

.banner-setting-container {
    .btn-submit {
        padding: 0.625rem 1.25rem;
        height: auto;
        color: $white;
        background-color: $primary;
        border-color: $primary;
        width: auto;
    }

    .marketplace-banner {
        .upload {
            bottom: -95px;
        }
        .upload .btn-upload {
            overflow: unset;
        }
        .ant-form-item {
            margin-bottom: unset;
        }
    }

    .image-form-container {
        .ant-form-item-control-input {
            min-height: unset;
        }
    }
}
