@import '@styles/common/variables';
@import '@styles/common/mixins';

.subscriptionPage {
    background-image: url('../../../public/images/plan-background.png') !important;

    :global {
        .ant-spin-nested-loading {
            overflow-y: auto;
        }
    }

    .subscriptionContainer {
        @include min-sm {
            padding: rem(40);
            min-width: $screen-sm;
            border-radius: rem(12);
        }
        padding: rem(8);
        border-radius: rem(8);
        background-color: $white;
        margin: rem(12);
    }

    .headerContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: rem(32);
        padding: rem(24) rem(32);
        text-transform: uppercase;
        border-bottom: 1px solid $gray-200;
        @include text-style(700, rem(24), rem(20), $primary-text);

        .logoContainer {
            padding-bottom: rem(16);

            .logoImg {
                height: rem(45);
            }
        }
        @include max-sm {
            @include text-style(700, rem(20), rem(28), $primary-text);
        }
    }

    .subscriptionItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: rem(8);

        span {
            @include text-style(500, rem(16), rem(24));
        }

        @include max-sm {
            span {
                @include text-style(500, rem(12), rem(20));
            }
        }
    }

    .btn {
        width: 100%;
        height: rem(48);
        text-align: center;
        margin-top: rem(16);
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        @include text-style(700, rem(16), rem(24), $white);
    }
}
