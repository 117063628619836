@import '@styles/common/variables';
@import '@styles/common/mixins';

.notificationPaymentModal {
    .successImg {
        max-width: rem(374);
    }

    .title {
        text-align: center;
        @include text-style(700, rem(20), rem(42), $primary);
    }

    .btnWrap {
        width: 100%;

        .backBtn {
            width: 100%;
            border: none;
            height: rem(48);
            border-radius: rem(12);
            background-color: $primary;
        }

        :global {
            .ant-btn-loading-icon {
                display: flex;
            }
        }
    }

    :global {
        .ant-modal-close {
            top: 20px;
        }
    }
}
