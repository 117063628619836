@import '@styles/common/variables';

.collaboratorDrawerContainer {
    :global {
        .ant-card {
            box-shadow: none;
        }

        .ant-page-header-heading {
            display: none;
        }

        .ant-page-header {
            padding: rem(24);
        }

        .ant-card-actions {
            border: none;
        }
    }
}

.collaboratorFormContainer {
    :global {
        .ant-form-item-label > label {
            font-weight: 500;
        }

        .ant-input,
        .ant-input-password {
            &:hover,
            &:focus {
                border-color: var(--primary);
                box-shadow: 0 0 0 rem(2) $neutral-10-bg;
            }

            &::placeholder {
                font-weight: 400;
                font-size: rem(16);
            }
        }

        .ant-input,
        .ant-input-number-input,
        .ant-picker,
        .ant-input-password,
        .ant-select {
            width: 100%;
            height: rem(42);
            @include text-style(rem(16), 400, rem(24));
        }

        .ant-input-password {
            .ant-input {
                height: rem(42);

                &:hover,
                &:focus {
                    border: none;
                    background: none;
                    box-shadow: none;
                }
            }
        }

        .ant-select-selector:hover,
        .ant-select-selector:focus,
        .ant-input:hover,
        .ant-input-number:hover,
        .ant-input-number:focus,
        .ant-input:focus {
            border: 1px solid var(--primary);
        }

        .ant-upload.ant-upload-select-picture-card {
            margin: 0;
            padding: 0;

            img {
                object-fit: cover;
            }

            .upload {
                margin-top: rem(10);
            }
        }

        .ant-select-selector {
            padding: rem(6) rem(12) !important;
            height: 100% !important;

            .ant-select-selection-search-input {
                height: 100% !important;
                @include text-style(rem(16), 500, rem(24));
            }
        }

        .ant-select-multiple .ant-select-selection-item {
            height: auto;
            min-height: rem(24);
        }

        .ant-checkbox-checked .ant-checkbox-inner,
        .ant-picker-range .ant-picker-active-bar {
            border-color: var(--primary);
            background-color: var(--primary);
            box-shadow: 0 0 0 rem(2) $neutral-10-bg;
        }

        .ant-radio-checked .ant-radio-inner {
            border-color: var(--primary);
        }
    }
}
