@import '@styles/common/variables';

.business_detail {
    .avatar {
        .ant-avatar {
            aspect-ratio: 1 / 1;
            width: 104px;
            height: unset;
            max-width: 100%;
        }

        .ant-form-item-control-input-content {
            display: flex;
            justify-content: center;
        }

        .ant-upload-picture-card-wrapper {
            display: flex;
            justify-content: center;

            .ant-upload.ant-upload-select-picture-card {
                // background-image: url(../../../public/images/icons/avatar.svg);
                box-shadow:
                    0px 12px 16px -4px rgba(16, 24, 40, 0.08),
                    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
                border: 4px solid #ffffff;
                background-repeat: no-repeat;
                background-position: center;
                border-radius: 50%;
                border: none;
                aspect-ratio: 1 / 1;
                width: 104px;
                height: unset;
                max-width: 100%;
                margin: 0;

                &>.ant-upload {
                    img {
                        border-radius: 50%;
                    }
                }

                .upload {
                    .btn-upload {
                        display: none;
                    }
                }
            }
        }
    }

    .full-name {
        text-align: center;
        font-weight: 500;
        font-size: 15px;
        line-height: 23px;
    }

    .company {
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
    }

    .btn-group {
        display: flex;
        justify-content: center;
        margin-top: 10px;

        button {
            font-size: 11px;
            line-height: 16px;
            height: 22px;
        }

        .edit-cancel {}

        .edit-submit {}
    }

    .ant-form-item:focus-within {
        .ant-form-item-label {
            label {
                color: #109cf1;
            }
        }
    }

    .ant-form-item-label {
        padding-bottom: 0;

        label {
            font-weight: 500;
            font-size: 11px;
            line-height: 16px;
            color: #818e9b;
        }
    }

    &.show-mode {
        label.ant-form-item-required::before {
            display: none;
        }
    }
}

.tenant-detail {
    .ant-input {
        min-height: 44px;
    }

    .ant-card-body {
        padding: 0;
    }
}

.toggle-button {
    .ant-input-affix-wrapper>input.ant-input {
        min-height: 32px;
        height: 32px;
    }
}