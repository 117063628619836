@import '@styles/common/variables';
.upload {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    .btn-upload {
        padding: 1.5rem 1rem;
        display: flex;
        align-items: center;
        font-size: 14px;
        overflow: hidden;
        word-wrap: normal;

        .anticon {
            font-size: 24px;
        }
    }

    .upload-text {
        font-size: 13px;
    }
    .ant-btn-default {
        box-shadow: none;
    }
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover {
    border-color: $primary;
}

.upload-img {
    .hide-button {
        display: none;
    }
    .upload {
        left: -24px;
    }
}

.list-image {
    object-fit: cover;
    height: 100px;
    width: 100%;
    cursor: pointer;
    transition: all 0.5s;

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }
}
.active-image {
    border: 5px solid $accent-border;
}

.inactive-image {
    border: 0px solid $accent-border;
}

.modal-image {
    overflow-x: hidden;
    overflow-y: auto;
    height: 350px;
}

.tabs {
    .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
        display: block;
        margin: 0 auto;
    }
}

.search-custom {
    width: 100%;
    margin-bottom: 1rem;
}

.unload {
    display: none;
}
