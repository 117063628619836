@import '@styles/common/mixins';
@import '@styles/common/variables';

.modalCustom {
    :global {
        .ant-modal-content {
            border-radius: 24px;
            padding: 40px;
        }

        .ant-modal-body {
            padding: 0;
        }

        .ant-modal-close {
            top: 0;
            right: 0;
            width: unset;
            height: unset;
            &:active,
            &:hover {
                background-color: transparent;
            }
        }

        .ant-modal-close-x {
            margin-top: 20px;
            margin-right: 20px;
            height: 40px;
            width: 40px;
        }
    }
}
