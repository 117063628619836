@import '/src/styles/common/variables';

.tenant-users-setting-container {
    .header-action-row {
        .header-action-col {
            display: flex;
        }
    }

    .pagination-container {
        border: 1px solid #eaecf0;
        border-top: none;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .users-total-container,
    .roles-total-container {
        height: rem(72);
        border: 1px solid $gray-10;
        border-bottom: 0;
        border-top-left-radius: rem(6);
        border-top-right-radius: rem(6);
        padding: rem(24) rem(20);

        .total-users,
        .total-roles {
            .all-users,
            .all-roles {
                @include text-style(500, rem(20), rem(30), $gray-900);
            }

            .total-number-users,
            .total-number-roles {
                @include text-style(500, rem(16), rem(24), $ant-active-text);
                background-color: $ant-active-bg;
                border-radius: rem(16);
                padding: rem(2) rem(10) rem(2) rem(10);
            }
        }
    }

    .show-all-button,
    .create-user-button {
        height: rem(40);
    }

    .fade {
        opacity: 0.2;
    }

    .show-all-button {
        @include text-style(500, rem(16), rem(24), $primary-text);
    }

    .create-user-button {
        @include text-style(500, rem(16), rem(24), #ffffff);
    }

    .users-table-container {
        .users-table {
            .ant-table-body {
                table {
                    table-layout: auto !important;
                }
            }

            .user-cell {
                .user-cell-subject {
                    @include text-style(500, rem(16), rem(24), $primary-text);
                }

                .user-cell-message {
                    @include text-style(500, rem(14), rem(20), $gray-500);
                }
            }

            .status-cell {
                .status-cell-label {
                    padding: rem(2) rem(10) rem(2) rem(8);
                    border-radius: rem(16);

                    width: fit-content;

                    .dot {
                        width: rem(6);
                        height: rem(6);
                        border-radius: 50%;
                    }

                    &.pending {
                        color: $ant-active-text;
                        background-color: $ant-active-bg;
                        .dot {
                            background-color: $ant-active-text;
                        }
                    }

                    &.active,
                    &.infor_updated,
                    &.account_created {
                        color: $success-700;
                        background-color: $success-50;
                        .dot {
                            background-color: $success-700;
                        }
                    }

                    &.deactivated {
                        color: $neutral-black-4;
                        background-color: $neutral-black-1;
                        .dot {
                            background-color: $neutral-black-4;
                        }
                    }
                }
            }
        }
    }

    .roles-table-container {
        .roles-table {
            .ant-table-body {
                table {
                    table-layout: auto !important;
                }
            }

            .role-cell {
                .role-cell-subject {
                    @include text-style(500, rem(16), rem(24), $primary-text);
                }

                .role-cell-message {
                    @include text-style(500, rem(14), rem(20), $gray-500);
                }
            }
        }
    }

    .users-table-container,
    .roles-table-container {
        .ant-table,
        .ant-table-container,
        .ant-table-container table > thead > tr:first-child th:first-child,
        .ant-table-container table > thead > tr:first-child th:last-child {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    .edit-user-button,
    .delete-user-button {
        padding: 0;
        border: none;
        background-color: transparent;
        box-shadow: none;
        &::after {
            display: none;
        }
    }

    .delete-user-button {
        svg path {
            fill: $danger;
        }
    }
}
